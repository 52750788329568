import React, { FC } from 'react';

import { Layout } from 'layout';
import ProductListComponent from 'layout/ProductListComponent';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { ProductListingProps } from './models';

import './productListingComponent.scss';

const ProductListingComponent: FC<ProductListingProps> = ({
  node: { section, seoMetaTitle, seoMetaDescription, seoMetaKeywords, purchaseLinkText },
}) => (
  <Layout>
    <Seo {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }} />
    <PageSchema
      type="WebPage"
      name={seoMetaTitle}
      data={{
        metaTitle: seoMetaTitle,
        metaDescription: seoMetaDescription,
        metaKeywords: seoMetaKeywords,
      }}
    />
    <div className="content container">
      {section.map(({ title, products }) => (
        <div key={title} className="content__products row">
          <div className="content__title col-12">
            <h2 className="title" key={title}>
              {title}
            </h2>
          </div>
          {products.map((product) => {
            const { name } = product;

            return (
              <article className="content__product col-6 col-md-3" key={name}>
                <ProductListComponent product={product} {...{ purchaseLinkText }} />
              </article>
            );
          })}
        </div>
      ))}
    </div>
  </Layout>
);

export default ProductListingComponent;
